//import CookieConsent from 'vanilla-cookieconsent/dist/cookieconsent.umd';
import * as CookieConsent from 'vanilla-cookieconsent';
const mapsSrc =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d989.1948841123025!2d8.709353093550336!3d53.865712302201445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b402eabd3b237f%3A0xeff3d7a4e01bc0ba!2sNEXTcontrol%20GmbH!5e0!3m2!1sde!2sde!4v1743417409487!5m2!1sde!2sde';
CookieConsent.run({
  // root: 'body',
  // autoShow: true,
  disablePageInteraction: true,
  hideFromBots: true,
  // mode: 'opt-in',
  // revision: 0,
  cookie: {
    name: 'cc_cookie',
    // domain: location.hostname,
    // path: '/',
    // sameSite: "Lax",
    expiresAfterDays: 365,
  },
  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
    consentModal: {
      layout: 'cloud',
      position: 'bottom center',
      //equalWeightButtons: true,
      //flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      //equalWeightButtons: true,
      //flipButtons: false
    },
  },
  /*
    onFirstConsent: ({ cookie }) => {
      console.log('onFirstConsent fired', cookie);
    },

    onConsent: ({ cookie }) => {
      console.log('onConsent fired!', cookie)
    },

    onChange: ({ changedCategories, changedServices }) => {
      console.log('onChange fired!', changedCategories, changedServices);
    },

    onModalReady: ({ modalName }) => {
      console.log('ready:', modalName);
    },

    onModalShow: ({ modalName }) => {
      console.log('visible:', modalName);
    },

    onModalHide: ({ modalName }) => {
      console.log('hidden:', modalName);
    },
    */
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
      cookies: {
        name: 'cc_cookie',
      },
    },
    analytics: {
      enabled: true,
      autoClear: {
        cookies: [
          {
            name: /^(_ga)/, //regex
          },
          {
            name: '_gid', //string
          },
          {
            name: '_ga_2S9VXCF20N', //string
          },
        ],
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
      services: {
        matomo: {
          label: 'Google Analytics',
          onAccept: () => {
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-PHF8WZ4V');
          },
          onReject: () => {},
          cookies: [
            {
              name: '_ga', //string
            },
            {
              name: '_gs', //string
            },
            {
              name: '_ga_2S9VXCF20N', //string
            },
          ],
        },
      },
    },
    functional: {
      enabled: true,
      autoClear: {
        cookies: [
          {
            name: '_gm',
          },
        ],
      },
      services: {
        maps: {
          label: 'Google Maps',
          onAccept: () => {
            const maps = document.getElementById('maps');
            const btn = document.getElementById('mapsButton');
            if (maps !== null && btn !== null) {
              btn.classList.add('d-none');
              maps.classList.remove('d-none');
              maps.setAttribute('src', mapsSrc);
            }
          },
          onReject: () => {
            const btn = document.getElementById('mapsButton');
            const maps = document.getElementById('maps');
            if (maps !== null && btn !== null) {
              maps.classList.add('d-none');
              maps.removeAttribute('src');
              btn.classList.remove('d-none');
            }
          },
          cookies: [
            {
              name: '_gm', //string
            },
          ],
        },
      },
    },
    ads: {},
  },
  language: {
    default: 'en',
    autoDetect: 'browser',
    translations: {
      en: '/translations/cookies-en.json',
      de: '/translations/cookies-de.json',
    },
  },
});
